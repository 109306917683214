import { flatten } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL } from '../constants/BoltConstants';
import {
  NEW_YORK_STATE_FAIR_HOUSING_NOTICE,
  NEW_YORK_STATE_STANDARD_OPERATING_PROCEDURE,
} from '../constants/PdfConstants';
import { OfficesResponse } from '../openapi/bff';
import { LegaleseElement } from '../openapi/wanderer';
import { TypeMarketsCountrySection } from '../types/generated/page-contents';
import {
  BrokerLicense,
  RealApiAgentWebsiteResponse,
} from '../types/real-api-types';
import { displayStateOrProvinceOfficeAddress } from '../utils/DisplayHelpers';
import GeneralTexasDisclaimers from './Disclaimers/GeneralTexasDisclaimers';
import MlsComplianceItemsRedesigned from './Disclaimers/MlsComplianceItemsRedesigned';

interface USFooterProps {
  disclaimers?: LegaleseElement[];
  brokerLicenses: BrokerLicense[];
  countriesWithStates: TypeMarketsCountrySection[];
  agent?: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

const USFooter: React.FC<USFooterProps> = ({
  countriesWithStates,
  brokerLicenses,
  disclaimers,
  agent,
  allOffices,
}) => {
  const { asPath } = useRouter();
  const usStates = flatten(
    countriesWithStates
      .filter((country) => country.fields.title === 'United States')
      .map((country) => country.fields.statesOrProvinces)
  );
  const licenses = brokerLicenses.map(
    (license) => `${license.symbol} : ${license.broker_license} `
  );
  const licenseString = licenses && licenses.join(', ');
  const isTXAgent = agent?.agent?.licensed_states?.includes('TX');

  return (
    <div className='mt-10'>
      <div className='py-2 md:pt-6'>
        <p className='font-inter text-[13px] text-light-gray-4 leading-6'>
          <strong className='font-inter font-bold text-[13px] text-light-gray-5'>
            Consumer Alert:
          </strong>{' '}
          Real is committed to protecting consumers. In our continuous effort to
          protect all customers, we discovered a few cases of fraudulent and
          fake representations on Craigslist in various states for short term
          rentals using Real logo. We do not solicit payments for third parties
          on CL and urge you not to send any payments to publishers of such ads.
          Please always contact us to confirm agent affiliation.
        </p>
        {disclaimers?.map((disclaimer, index) => (
          <div key={[disclaimer.text, index].join(', ')} className='mt-6'>
            {!!disclaimer?.asset?.url && (
              <div className='relative w-24 h-8'>
                <Image
                  src={disclaimer.asset.url}
                  layout='fill'
                  alt={disclaimer.text || 'MLS Image'}
                  objectFit='contain'
                  objectPosition='left'
                />
              </div>
            )}
            <p
              className='font-inter text-[13px] text-light-gray-4'
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: disclaimer.text }}
            />
          </div>
        ))}
      </div>
      <div className='py-2 md:py-6 leading-6'>
        <h4 className='font-inter font-bold text-[13px] text-white text-opacity-80'>
          Real Broker, LLC
        </h4>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Licensed Real Estate Broker
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker, LLC” in the following U.S. states:{' '}
          {usStates.map((state) => state.fields.title).join(', ')}.{' '}
          <Link
            href={`${process.env.NEXT_PUBLIC_JOINREAL_DOMAIN}/page/legal?tab=state-licenses#legal-tab`}
          >
            <a target='_blank' className='underline'>
              View state licenses.
            </a>
          </Link>
        </p>
        <p className='font-inter text-[13px] text-light-gray-4 mt-3'>
          Broker is licensed as “Real Brokerage Technologies, Inc”, in the State
          of California, CA DRE #2022092. Office Address: 1420 Kettner Blvd.
          #100, San Diego, CA 92101
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker AZ, LLC in the State of Arizona.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker CT, LLC in the State of
          Connecticut.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker MA, LLC in the State of
          Massachusetts.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker NH, LLC in the State of New
          Hampshire.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker NY, LLC and Real Broker, LLC in the
          State of New York.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Brokerage Technologies, Inc.” in the State
          of Ohio.
        </p>
        <p className='font-inter text-[13px] text-light-gray-4'>
          Broker is licensed as “Real Broker Commercial, LLC” in the states of
          Maryland, North Carolina, New Jersey, New York, South Carolina,
          Virginia and the District of Columbia.
        </p>

        <div className='my-4 text-light-gray-4'>
          <strong className='font-inter text-light-gray-6 text-[13px]'>
            State/Province Office Addresses:{' '}
          </strong>
          <ul className='mt-2'>
            {allOffices?.offices
              ?.sort((a, b) =>
                a?.stateOrProvince.localeCompare(b?.stateOrProvince)
              )
              ?.map((item) => (
                <li key={item.name} className='font-inter text-xs mb-1'>
                  {displayStateOrProvinceOfficeAddress(item)}
                </li>
              ))}
          </ul>
        </div>

        {asPath === '/about/states/TX' && <GeneralTexasDisclaimers />}

        <div className='mt-3 space-y-1 flex flex-col'>
          <Link
            href={
              isTXAgent && agent?.agent?.slug
                ? `/${agent.agent.slug}/iabs`
                : BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL
            }
          >
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              Texas Real Estate Commission Information About Brokerage Services
            </a>
          </Link>
          <Link href='https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-3.pdf'>
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              Texas Real Estate Commission Consumer Protection Notice
            </a>
          </Link>
        </div>

        <div className='my-3 space-y-1 flex flex-col'>
          <Link href={NEW_YORK_STATE_FAIR_HOUSING_NOTICE}>
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              New York State Fair Housing Notice
            </a>
          </Link>

          <Link href={NEW_YORK_STATE_STANDARD_OPERATING_PROCEDURE}>
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              New York State Standard Operating Procedures
            </a>
          </Link>

          <Link href='/agent-new-mexico-licenses'>
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              New Mexico Real Estate Licenses
            </a>
          </Link>

          <Link href='/agent-illinois-licenses'>
            <a
              target='_blank'
              rel='noreferer'
              className='underline font-inter text-[16px] text-light-gray-4'
            >
              Illinois Real Estate Licenses
            </a>
          </Link>
        </div>
        {licenseString && (
          <p className='font-inter text-[16px] text-light-gray-4 pb-3'>
            State Brokerage Licenses: {licenseString}
          </p>
        )}

        <MlsComplianceItemsRedesigned />
      </div>
    </div>
  );
};

export default USFooter;
