import { flatten } from 'lodash';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL } from '../../constants/BoltConstants';
import {
  NEW_YORK_STATE_FAIR_HOUSING_NOTICE,
  NEW_YORK_STATE_STANDARD_OPERATING_PROCEDURE,
} from '../../constants/PdfConstants';
import { OfficesResponse } from '../../openapi/bff';
import { LegaleseElement } from '../../openapi/wanderer';
import { TypeMarketsCountrySection } from '../../types/generated/page-contents';
import {
  BrokerLicense,
  RealApiAgentWebsiteResponse,
} from '../../types/real-api-types';
import { displayStateOrProvinceOfficeAddress } from '../../utils/DisplayHelpers';
import env from '../../utils/env';
import GeminiGeneralTexasDisclaimers from './GeminiDisclaimers/GeminiGeneralTexasDisclaimers';
import GeminiMlsComplianceItems from './GeminiDisclaimers/GeminiMlsComplianceItems';

interface GeminiUSFooterProps {
  disclaimers?: LegaleseElement[];
  brokerLicenses: BrokerLicense[];
  countriesWithStates: TypeMarketsCountrySection[];
  agent?: RealApiAgentWebsiteResponse;
  allOffices: OfficesResponse;
}

const GeminiUSFooter: React.FC<GeminiUSFooterProps> = ({
  countriesWithStates,
  brokerLicenses,
  disclaimers,
  agent,
  allOffices,
}) => {
  const { asPath } = useRouter();
  const usStates = flatten(
    countriesWithStates
      .filter((country) => country.fields.title === 'United States')
      .map((country) => country.fields.statesOrProvinces)
  );
  const licenses = brokerLicenses.map(
    (license) => `${license.symbol} : ${license.broker_license} `
  );
  const licenseString = licenses && licenses.join(', ');
  const isTXAgent = agent?.agent?.licensed_states?.includes('TX');

  return (
    <footer className='bg-cobalt font-inter font-normal text-[14px] leading-[21px] text-off-white text-opacity-70 pb-[70px]'>
      <section className='mt-5'>
        <h4 className='font-inter font-semibold text-[15px] leading-[22.5px] text-off-white text-opacity-100'>
          Consumer Alert:
        </h4>
        <p className='mt-2.5'>
          Real is committed to protecting consumers. In our continuous effort to
          protect all customers, we discovered a few cases of fraudulent and
          fake representations on Craigslist in various states for short term
          rentals using Real logo. We do not solicit payments for third parties
          on CL and urge you not to send any payments to publishers of such ads.
          Please always contact us to confirm agent affiliation.
        </p>
        {disclaimers?.map((disclaimer, index) => (
          <article key={[disclaimer.text, index].join('-')} className='mt-5'>
            {!!disclaimer?.asset?.url && (
              <figure className='relative w-24 h-8'>
                <Image
                  src={disclaimer.asset.url}
                  layout='fill'
                  alt={disclaimer.text || 'MLS Image'}
                  objectFit='contain'
                  objectPosition='left'
                />
              </figure>
            )}
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: disclaimer.text }}
            />
          </article>
        ))}
      </section>
      <section className='mt-[30px]'>
        <h4 className='font-inter font-semibold text-[15px] leading-[22.5px] text-off-white text-opacity-100'>
          Real Broker, LLC
        </h4>
        <article className='flex flex-col gap-5 mt-2.5'>
          <p>Licensed Real Estate Broker</p>
          <p>
            Broker is licensed as “Real Broker, LLC” in the following U.S.
            states: {usStates.map((state) => state.fields.title).join(', ')}.{' '}
            <Link
              href={`${env.NEXT_PUBLIC_JOINREAL_DOMAIN}/page/legal?tab=state-licenses#legal-tab`}
            >
              <a target='_blank' className='underline'>
                View state licenses.
              </a>
            </Link>
          </p>
          <section>
            <p>
              Broker is licensed as “Real Brokerage Technologies, Inc”, in the
              State of California, CA DRE #2022092. Office Address: 1420 Kettner
              Blvd. #100, San Diego, CA 92101
            </p>
            <p>
              Broker is licensed as “Real Broker AZ, LLC in the State of
              Arizona.
            </p>
            <p>
              Broker is licensed as “Real Broker CT, LLC in the State of
              Connecticut.
            </p>
            <p>
              Broker is licensed as “Real Broker MA, LLC in the State of
              Massachusetts.
            </p>
            <p>
              Broker is licensed as “Real Broker NH, LLC in the State of New
              Hampshire.
            </p>
            <p>
              Broker is licensed as “Real Broker NY, LLC and Real Broker, LLC in
              the State of New York.
            </p>
            <p>
              Broker is licensed as “Real Brokerage Technologies, Inc.” in the
              State of Ohio.
            </p>
            <p>
              Broker is licensed as “Real Broker Commercial, LLC” in the states
              of Maryland, North Carolina, New Jersey, New York, South Carolina,
              Virginia and the District of Columbia.
            </p>
          </section>
        </article>
        <article className='mt-[30px]'>
          <h4 className='font-inter font-semibold text-[15px] leading-[22.5px] text-off-white text-opacity-100'>
            State/Province Office Addresses:
          </h4>
          <ul className='mt-2.5'>
            {(allOffices?.offices || [])
              ?.sort((a, b) =>
                a?.stateOrProvince.localeCompare(b?.stateOrProvince)
              )
              ?.map((item, i) => (
                <li key={[item.name, i].join('-')}>
                  {displayStateOrProvinceOfficeAddress(item)}
                </li>
              ))}
          </ul>
        </article>

        {asPath === '/about/states/TX' && <GeminiGeneralTexasDisclaimers />}

        <nav className='mt-5'>
          <Link
            href={
              isTXAgent && agent?.agent?.slug
                ? `/${agent.agent.slug}/iabs`
                : BOLT_CUSTOM_ASSET_FOR_TEXAS_IABS_PDF_URL
            }
          >
            <a target='_blank' rel='noreferer' className='underline'>
              Texas Real Estate Commission Information About Brokerage Services
            </a>
          </Link>
        </nav>

        <nav className='mt-5'>
          <Link href='https://www.trec.texas.gov/sites/default/files/pdf-forms/CN%201-3.pdf'>
            <a target='_blank' rel='noreferer' className='underline'>
              Texas Real Estate Commission Consumer Protection Notice
            </a>
          </Link>
        </nav>

        <nav className='mt-5'>
          <Link href={NEW_YORK_STATE_FAIR_HOUSING_NOTICE}>
            <a target='_blank' rel='noreferer' className='underline'>
              New York State Fair Housing Notice
            </a>
          </Link>
        </nav>

        <nav className='mt-5'>
          <Link href={NEW_YORK_STATE_STANDARD_OPERATING_PROCEDURE}>
            <a target='_blank' rel='noreferer' className='underline'>
              New York State Standard Operating Procedures
            </a>
          </Link>
        </nav>

        <nav className='mt-5'>
          <Link href='/agent-new-mexico-licenses'>
            <a target='_blank' rel='noreferer' className='underline'>
              New Mexico Real Estate Licenses
            </a>
          </Link>
        </nav>

        <nav className='mt-5'>
          <Link href='/agent-illinois-licenses'>
            <a target='_blank' rel='noreferer' className='underline'>
              Illinois Real Estate Licenses
            </a>
          </Link>
        </nav>

        {!!licenseString && (
          <section className='mt-[30px]'>
            <h4 className='font-inter font-semibold text-[15px] leading-[22.5px] text-off-white text-opacity-100'>
              State Brokerage Licenses:
            </h4>
            <p className='pt-2.5'>{licenseString}</p>
          </section>
        )}

        <GeminiMlsComplianceItems />
      </section>
    </footer>
  );
};

export default GeminiUSFooter;
